<script setup lang="ts">
import type { RouteObject } from "@shopware-pwa/composables-next";

const props = defineProps<{
  error: {
    statusCode: number;
    statusMessage: string;
    message: string;
  };
}>();

let isFormattedError = true;
let errMessage = "";
let linkFormatter = (path: string | RouteObject) => path;

try {
  const { t } = useI18n();
  const localePath = useLocalePath();
  const { formatLink } = useInternationalization(localePath);
  linkFormatter = formatLink;

  const errorMessageMap: { [key: number]: string } = {
    404: t("errorPages.404"),
    408: t("errorPages.408"),
    500: t("errorPages.500"),
    502: t("errorPages.502"),
    503: t("errorPages.503"),
  };

  errMessage =
    props.error.statusMessage ||
    errorMessageMap[props.error.statusCode as keyof typeof errorMessageMap];

  if (props.error.statusCode === 412) {
    // setting a timeout here to ensure we are the last error message in terminal
    setTimeout(() => {
      console.error(
        "Looks like your API connection is not working. Check your nuxt configuration (shopwareEndpoint and shopwareAccessToken). 🤞",
      );
      console.error(
        "For more help ➡️  https://frontends.shopware.com/resources/troubleshooting.html",
      );
    }, 2.0 * 1000);
  }
} catch (e) {
  console.error("Problem with loading error page", e);
  isFormattedError = false;
}

const statusCode = isFormattedError ? props.error.statusCode : "Error";
const errorMessage = isFormattedError ? errMessage : props.error.message;

console.log('props.error.message: ', props.error.message)

const { loadNavigationElements } = useNavigation();
const { data } = useAsyncData("mainNavigation", () => {
  return loadNavigationElements({ depth: 1 });
});
provide("swNavigation-main-navigation", data);

const { loadNavigationElements: loadFooterNavigationElements } = useNavigation({
  type: "footer-navigation",
});
const { data: footerData } = useAsyncData("mainFooterNavigation", () => {
  return loadFooterNavigationElements({ depth: 1 });
});
provide("swNavigation-footer-navigation", footerData);
</script>

<script lang="ts">
export default {
  name: "ErrorPage",
};
</script>

<template>
  <LayoutHeader />
  <div
    class="px-5 py-3 md:py-20 md:px-32 lg:px-24 lg:py-24 items-center flex justify-center flex-col-reverse lg:flex-row"
  >
    <div class="flex flex-col items-center justify-center my-8">
      <div class="max-w-md text-center">
        <h1 class="mb-4 font-extrabold text-9xl">
          <span class="sr-only">{{ $t("error") }}</span
          >{{ statusCode }}
        </h1>
        <p
          v-if="errorMessage"
          class="text-xl md:text-3xl font-semibold mt-4 mb-8"
        >
          {{ errorMessage }}
        </p>
        
        <NuxtLink
          :to="isFormattedError ? linkFormatter(`/`) : `/`"
          class="btn btn-danger btn-small mt-5"
        >
          {{ $t("goBackHome") }}
        </NuxtLink>
      </div>
    </div>
  </div>
  <LayoutFooter></LayoutFooter>
</template>
