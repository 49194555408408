<script setup lang="ts">
import {
  getCategoryRoute,
  getTranslatedProperty,
} from "@shopware-pwa/helpers-next";
import type { Schemas } from "#shopware";
type NavigationElement = Schemas["Category"] & {
  activeClass?: boolean;
};

const props = defineProps<{
  type: string;
}>();

const { navigationElements } = useNavigation();
const currentMenuPosition = ref<string | undefined>(undefined);
const resetActiveClass = ref<boolean>(true);

const route = useRoute();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

onMounted(() => {
  let currentNaviagtionElement: NavigationElement | undefined;
  if (navigationElements.value) {
    currentNaviagtionElement = findNavigationElement(
      route.path.slice(1),
      navigationElements.value,
    );
  }
  if (currentNaviagtionElement) {
    updateActiveClass(
      currentNaviagtionElement.id,
      currentNaviagtionElement.parentId,
    );
  }
});

const findNavigationElement = (
  routePath: string,
  navigation: NavigationElement[],
): NavigationElement | undefined => {
  for (let i = 0; i < navigation.length; i++) {
    const navigationElement = navigation[i];
    const seoUrls = navigationElement.seoUrls as
      | Schemas["SeoUrl"][]
      | undefined;
    if (seoUrls) {
      for (let j = 0; j < seoUrls.length; j++) {
        const currentSeoUrl = seoUrls[j];
        if (currentSeoUrl && currentSeoUrl.seoPathInfo === routePath) {
          return navigationElement;
        }
      }
    }
    const children = navigationElement.children;
    if (children) {
      const foundElement = findNavigationElement(routePath, children);
      if (foundElement) {
        return foundElement;
      }
    }
  }
  return undefined;
};

const onUpdateActiveClass = (
  navigationId: string,
  parentId: string | undefined,
) => {
  updateActiveClass(navigationId, parentId);
};

const resetNavigationActiveClass = (navigation: NavigationElement[]) => {
  for (let ni = 0; ni < navigation.length; ++ni) {
    navigation[ni].activeClass = false;
    const children = navigation[ni].children;
    if (children) {
      resetNavigationActiveClass(children);
    }
  }
};

function toggleMobileNavigation(navigation: NavigationElement[]) {
    navigation.dropdown = !navigation.dropdown;
}

const updateActiveClass = (
  navigationId: string,
  parentId: string | undefined,
) => {
  const setNavigationActiveClass = (
    navigation: NavigationElement[],
    navigationId: string,
    parentId: string | undefined,
  ) => {
    for (let ni = 0; ni < navigation.length; ++ni) {
      if (navigation[ni].id === navigationId) {
        navigation[ni].activeClass = true;
      }
      if (navigation[ni].id == parentId) {
        navigation[ni].activeClass = true;
        if (navigationElements.value) {
          setNavigationActiveClass(
            navigationElements.value,
            navigationId,
            navigation[ni].parentId,
          );
        }
      }
      const children = navigation[ni].children;
      if (children) {
        setNavigationActiveClass(children, navigationId, parentId);
      }
    }
    let collapse = window.document.getElementById('mobile-header-collapse');
    collapse.classList.remove('is-active');
    let trigger = window.document.getElementById('js-nav-trigger');
    if(trigger) {
    trigger.classList.remove('nav-trigger--active');
    }
    window.document.body.classList.remove("no-scroll")
  };

  if (navigationElements.value) {
    resetNavigationActiveClass(navigationElements.value);
    setNavigationActiveClass(navigationElements.value, navigationId, parentId);
    resetActiveClass.value = false;
  }
};
// reset when route.path changes
watch(
  () => route.path,
  () => {
    if (resetActiveClass.value == true && navigationElements.value) {
      resetNavigationActiveClass(navigationElements.value);
    }
    resetActiveClass.value = true;
  },
);
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <nav class="navbar-collapse position-relative" id="navbarContent" aria-label="Top navigation" role="menu">
    <ul class="navbar-nav">
      <li v-for="navigationElement in navigationElements"
      :key="navigationElement.id" class="nav-item"
        :class="{'drop-down': navigationElement?.children.length}"
      @mouseover="currentMenuPosition = navigationElement.id">
        <template v-if="props.type != 'mobile'">
          <NuxtLink role="menuitem" :target="navigationElement.externalLink || navigationElement.linkNewTab
            ? '_blank' : ''"
                    :to="formatLink(getCategoryRoute(navigationElement))"
                    :class="{'link-active': (navigationElement as NavigationElement).activeClass}"
                    class="nav-link"
                    @click="updateActiveClass(navigationElement.id, navigationElement.parentId)">
            <span>{{ getTranslatedProperty(navigationElement, "name") }}</span>
          </NuxtLink>
        </template>
        <template v-else>
          <a
             href="#"
             :class="{'show': (navigationElement as NavigationElement).dropdown}"
             class="nav-link dropdown-toggle"
             id="navbarDropdown"
             role="button" data-bs-toggle="dropdown" aria-expanded="true"
             @click="toggleMobileNavigation(navigationElement)"
          >
            <span>{{ getTranslatedProperty(navigationElement, "name") }}</span>
          </a>
        </template>

      <client-only>
        <ul class="dropdown-menu" :class="{'show': (navigationElement as NavigationElement).dropdown}" @mouseleave="currentMenuPosition = undefined">
          <template v-if="navigationElement.children.length > 0">
            <LayoutTopNavigationRecursive
                :navigation-element-children="navigationElement.children"
                @update-active-class="onUpdateActiveClass"
            />
          </template>
        </ul>
      </client-only>
    </li>
    </ul>
  </nav>
</template>

<style scoped>
nav .link-active {
  @apply text-secondary-900 bg-primary bg-opacity-10 rounded-lg;
}
</style>
