<script setup lang="ts">
import {
  getTranslatedProperty,
  getCategoryRoute,
} from "@shopware-pwa/helpers-next";

const props = defineProps<{
  navtype: string;
}>();

const { navigationElements } = useNavigation({ type: 'footer-navigation' });
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const { t } = useI18n();


</script>

<template>
  <div
      v-for="(navigationElement, index) in navigationElements"
      :key="navigationElement.id"
  >
    <nav class="footer__nav" v-if="(navtype == 'footer-navigation' && index == 0) || (navtype == 'service-navigation' && index == 1)">
        <h3>{{ getTranslatedProperty(navigationElement, "name") }}</h3>
        <template v-if="navigationElement.childCount > 0">
          <ul class="list-none p-0 mb-5">
            <li
                v-for="navigationChild in navigationElement.children"
                :key="navigationChild.id"
                class=""
            >
              <nuxt-link
                  :target="
              navigationChild.linkNewTab
              ? '_blank'
              : ''
          "
                  :to="formatLink(getCategoryRoute(navigationChild))"
                  class="text-base font-normal text-secondary-500 hover:text-secondary-900"
              >
                {{ getTranslatedProperty(navigationChild, "name") }}
              </nuxt-link>
            </li>
          </ul>
        </template>
    </nav><!-- /.footer__nav -->
  </div>
</template>
