import account from "../fr-CH/account.json";
import form from "../fr-CH/form.json";
import changePassword from "../fr-CH/changePassword.json";
import recoveryPassword from "../fr-CH/recoveryPassword.json";
import checkout from "../fr-CH/checkout.json";
import general from "../fr-CH/general.json";
import cart from "../fr-CH/cart.json";
import listing from "../fr-CH/listing.json";
import product from "../fr-CH/product.json";
import newsletter from "../fr-CH/newsletter.json";
import validations from "../fr-CH/validations.json";

export default {
  ...account,
  ...form,
  ...changePassword,
  ...recoveryPassword,
  ...checkout,
  ...general,
  ...cart,
  ...listing,
  ...product,
  ...newsletter,
  ...validations,
};
