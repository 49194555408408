<script setup lang="ts">
import type { Schemas } from "#shopware";
import {
  getSmallestThumbnailUrl,
  getTranslatedProperty,
} from "@shopware-pwa/helpers-next";

const props = defineProps<{ product: Schemas["Product"] }>();

const { product } = toRefs(props);
const { unitPrice, displayFrom } = useProductPrice(product);

const DEFAULT_THUMBNAIL_SIZE = 10;
const imageElement = ref(null);
const { width, height } = useElementSize(imageElement);

function roundUp(num: number) {
  return num ? Math.ceil(num / 100) * 100 : DEFAULT_THUMBNAIL_SIZE;
}

const srcPath = computed(() => {
  const coverMedia = product.value.cover?.media;
  const coverUrl = coverMedia ? getSmallestThumbnailUrl(coverMedia) : '/assets/images/placeholder.gif';

  const biggestParam =
      width.value > height.value
          ? `width=${roundUp(width.value)}`
          : `height=${roundUp(height.value)}`;

  return `${coverUrl}?${biggestParam}&fit=crop,smart`;
});

</script>
<template>
  <div class="product-search-item">
    <div class="product-image">
      <img
        ref="imageElement"
        loading="lazy"
        data-testid="layout-search-suggest-image"
        :src="srcPath ? srcPath : '/assets/images/placeholder.gif'"
        class="h-8 w-8 object-cover"
        alt="Product image"
      />
    </div>
    <div class="product-info">
      <div data-testid="layout-search-suggest-name" class="product-name">
        {{ getTranslatedProperty(product, "name") }}
      </div>
      <div class="product-price">
        <SharedPrice
          v-if="unitPrice"
          data-testid="layout-search-suggest-price"
          class="justify-end"
          :value="unitPrice"
        >
          <template #beforePrice>
            <span v-if="displayFrom">{{ $t("product.price.from") }}</span>
          </template>
        </SharedPrice>
        <ProductUnits
          data-testid="layout-search-suggest-units"
          :product="product"
          :show-content="false"
          class="text-3"
        />
      </div>
    </div>
  </div>
</template>
