<script setup lang="ts">
import {getCmsPageById} from "~/server/adminApiClient";
import {getPrefix} from "~/i18n/src/helpers/prefix";

const layoutId = "018dd18df0e97933856df821556244ef"

const router = useRouter();
const { availableLocales, defaultLocale } =
    useI18n();
const prefix = getPrefix(
    availableLocales,
    router.currentRoute.value.name as string,
    defaultLocale,
);
const {
  getLanguageIdFromCode,
} = useInternationalization();
let languageToChangeId: string | null = null;

languageToChangeId = getLanguageIdFromCode(
    prefix ? prefix : defaultLocale,
);

const layoutData = await getCmsPageById(layoutId, languageToChangeId)

</script>
<template>
  <CmsPage v-if="layoutData" :content="layoutData[0]" />
</template>