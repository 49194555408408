import axios from 'axios';

export const getCmsPageById = async (cmsPageId: any, languageId: any) => {
    const { $adminGetAccessData, $adminApiEndpoint } = useNuxtApp()
    const tokenData = await $adminGetAccessData();
    const bearerToken = tokenData.data.access_token;

    const requestBody = {
        "ids": cmsPageId,
        "page": 1,
        "limit": 1,
        "associations": {
            "sections": {
                "sort": [
                    {
                        "field": "position",
                        "order": "ASC",
                        "naturalSorting": true
                    }
                ],
                "associations": {
                    "backgroundMedia": {
                        "total-count-mode": 1
                    },
                    "blocks": {
                        "sort": [
                            {
                                "field": "position",
                                "order": "ASC",
                                "naturalSorting": true
                            }
                        ],
                        "associations": {
                            "backgroundMedia": {
                                "total-count-mode": 1
                            },
                            "slots": {
                                "total-count-mode": 1
                            }
                        },
                        "total-count-mode": 1
                    }
                },
                "total-count-mode": 1
            },
        },
        "total-count-mode": 1
    }

    try {
        const apiUrl = $adminApiEndpoint + "/search/cms-page";
        const headers = {
            "Authorization": `Bearer ${bearerToken}`,
            "Content-Type": "application/json",
            "Sw-Language-Id": languageId
        };

        const response = await axios.post(apiUrl, requestBody, { headers });

        if (!response.data || response.status !== 200) {
            throw new Error(`Request failed with status: ${response.status}`);
        }

        return response.data.data;
    } catch (error) {
        // Handle errors
        console.error("Error:", error);
        throw error; // Re-throw the error to propagate it further if needed
    }
}

export const getCategoryById = async (categoryId: any, languageId: any ) => {
    const { $adminGetAccessData, $adminApiEndpoint } = useNuxtApp()
    try {
        const tokenData = await $adminGetAccessData();
        const bearerToken = tokenData.data.access_token;

        const apiEnd = $adminApiEndpoint + "/search/category";
        const headers = {
            "Authorization": `Bearer ${bearerToken}`,
            "Content-Type": "application/json",
            "Sw-Language-Id": languageId
        };

        // Assuming you have some data to send in the request body
        const data = {
            "ids": categoryId,
            "associations": {
                "products": {
                    "associations": {
                        "media": {}
                    }
                },
                "children": []
            }
        };

        const response = await axios.post(apiEnd, data, {
            headers: headers
        });

        return response.data;
    } catch (error) {
        throw error;
    }
}

export const updateOrderInformation = async (orderData: any) => {
    const { $adminGetAccessData, $adminApiClient } = useNuxtApp()
    const orderId = orderData.id
    await $adminGetAccessData();
    try {
        const operationName: string = "updateOrder patch /order/" + orderId +"?_response"; // Make sure this matches your API client library setup
        await $adminApiClient.invoke(operationName, {body: orderData});

    } catch (error) {
        console.error('Error updating Order:', error);
        throw error;
    }
}

export const getMediaById = async (mediaId: any)=> {

    const { $adminGetAccessData, $adminApiClient } = useNuxtApp()
    await $adminGetAccessData();

    try {
        const operationName = "getMedia get /media/" + mediaId;

        const response = await $adminApiClient.invoke(operationName, {});
        if (response) {
            return response.data;
        } else {
            throw new Error('Response or response data is undefined.');
        }
    } catch (error) {
        console.log('error:', error);
        return error;
    }
}