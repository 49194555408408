<script setup lang="ts">
import { useVuelidate } from "@vuelidate/core";
import { customValidators } from "@/i18n/utils/i18n-validators";
import { ApiClientError } from "@shopware/api-client";
import {klaviyoIdentify} from "~/components/utilities/GtmTrackings";

const { required, minLength, email, requiredIf } = customValidators();
const props = defineProps<{
  customerGroupId?: string;
}>();

const { getSalutations } = useSalutations();
const { getStatesForCountry } = useCountries();
const { register, isLoggedIn, user } = useUser();
const { pushError } = useNotifications();

const router = useRouter();
const loading = ref<boolean>();
const doubleOptInBox = ref();
const showDoubleOptInBox = ref(false);
const { t } = useI18n();
if (process.client && isLoggedIn.value) {
  // redirect to account page if user is logged in
  navigateTo({ path: "/checkout/cart" });
}

watch(isLoggedIn, (isLoggedIn) => {
  if (isLoggedIn) {
    klaviyoIdentify(user);
    navigateTo({ path: "/checkout/cart" });
  }
});

const initialState = {
  requestedGroupId: props.customerGroupId,
  salutationId: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  billingAddress: {
    street: "",
    zipcode: "",
    city: "",
    countryId: "",
    countryStateId: "",
  },
  acceptedDataProtection: true
};

const state = reactive(JSON.parse(JSON.stringify(initialState)));

const rules = computed(() => ({
  salutationId: {
    required,
  },
  firstName: {
    required,
    minLength: minLength(3),
  },
  lastName: {
    required,
    minLength: minLength(3),
  },
  email: {
    required,
    email,
  },
  password: {
    required,
    minLength: minLength(8),
  },
  billingAddress: {
    street: {
      required,
      minLength: minLength(3),
    },
    zipcode: {
      required,
    },
    city: {
      required,
    },
    countryId: {
      required,
    },
    countryStateId: {
      required: requiredIf(() => {
        return !!getStatesForCountry(state.billingAddress.countryId)?.length;
      }),
    },
  },
}));

const $v = useVuelidate(rules, state);
const { resolveApiErrors } = useApiErrorsResolver("account_login");

const invokeSubmit = async () => {
  $v.value.$touch();
  const valid = await $v.value.$validate();
  if (valid) {
    try {
      loading.value = true;
      const response = await register(state);
      if (response && response.active) router.push("/checkout/cart");
      else if (response && !response.active) {
        Object.assign(state, JSON.parse(JSON.stringify(initialState)));
        showDoubleOptInBox.value = true;
        await nextTick();
        doubleOptInBox.value.scrollIntoView();
        $v.value.$reset();
      }
    } catch (error) {
      if (error instanceof ApiClientError) {
        const errors = resolveApiErrors(error.details.errors);
        errors.forEach((error) => pushError(error));
      }
    } finally {
      loading.value = false;
    }
  }
};

useBreadcrumbs([
  {
    name: t("breadcrumbs.register"),
    path: "/register",
  },
]);
</script>
<template>
  <div class="">
    <div v-if="showDoubleOptInBox" ref="doubleOptInBox" class="alert alert-success">
      {{ $t("account.messages.signUpSuccess") }}
    </div>

    <form
      class="w-full relative"
      data-testid="registration-form"
      @submit.prevent="invokeSubmit"
    >
      <div class="form__body">
        <div class="form__body-inner">

          <div class="d-none form-check checkbox checkbox--large">
            <input class="form-check-input" type="checkbox" value="" id="check1">

            <label class="form-check-label" for="check1">Als Gast bestellen</label>
          </div>

          <div class="radios">
            <div class="form-check radio radio--primary" v-for="salutation in getSalutations">
              <input
                  type="radio"
                  :id="'salutation_' + salutation.id"
                  :value="salutation.id"
                  v-model="state.salutationId"
                  :name="'salutation_' + salutation.id"
                  class="form-check-input"
                  :class="{
                        'border-red-600': $v.salutationId.$error,
                         'border-normal': !$v.salutationId.$error}"
                  data-testid="registration-salutation-select"
                  @blur="$v.salutationId.$touch()">
              <label :for="'salutation_' + salutation.id" class="form-check-label">
                <span class="form-check-label__inner">
                  <i :class="'ico-' + salutation.displayName">

                    <img :src="'/assets/images/svg/'+ salutation.salutationKey + '.svg'" alt="">
                  </i>
              </span>

                <span>{{ salutation.displayName }}</span>

              </label>
            </div>
            <span v-if="$v.salutationId.$error" class="alert alert-danger">{{ $v.salutationId.$errors[0].$message }}</span>

          </div><!-- /.radios -->
        </div><!-- /.form__body-inner -->

        <div class="row form__row">
          <div class="col-md-6">
            <label for="first-name">{{ $t("form.firstName") }} *</label>
            <input
                id="first-name"
                v-model="state.firstName"
                name="first-name"
                type="text"
                autocomplete="first-name"
                :placeholder="$t('form.firstNamePlaceholder')"
                class="form-control"
                :class="[
              $v.firstName.$error
                ? 'border-danger'
                : 'border-normal',
            ]"
            />
            <span v-if="$v.firstName.$error" class="alert alert-danger">
            {{ $v.firstName.$errors[0].$message }}
          </span>
          </div>

          <div class="col-md-6">
            <label for="last-name">{{ $t("form.lastName") }} *</label>
            <input
                id="last-name"
                v-model="state.lastName"
                name="last-name"
                type="text"
                autocomplete="last-name"
                class="form-control"
                :class="[
              $v.lastName.$error
                ? 'border-danger'
                : 'border-normal',
            ]"
                :placeholder="$t('form.lastNamePlaceholder')"
                data-testid="registration-last-name-input"
                @blur="$v.lastName.$touch()"
            />
            <span v-if="$v.lastName.$error" class="alert alert-danger">
            {{ $v.lastName.$errors[0].$message }}
          </span>
          </div>
        </div>

        <div class="row form__row">
          <div class="col-md-6">
            <label for="email-address">{{ $t("form.email") }} *</label>
            <input
                id="email-address"
                v-model="state.email"
                name="email"
                type="email"
                autocomplete="email"
                :class="[
              $v.email.$error
               ? 'border-danger'
                : 'border-normal',
            ]"
                class="form-control"
                :placeholder="$t('form.emailPlaceholder')"
                data-testid="registration-email-input"
                @blur="$v.email.$touch()"
            />
            <span
                v-if="$v.email.$error"
                class="alert alert-danger"
            >
            {{ $v.email.$errors[0].$message }}
          </span>
          </div>

          <div class="col-md-6">
            <label for="password">{{ $t("form.password") }} *</label>
            <input
                id="password"
                v-model="state.password"
                name="password"
                type="password"
                autocomplete="password"
                class="form-control"
                :class="[
              $v.password.$error
                ? 'border-danger'
                : 'border-normal',
            ]"
                :placeholder="$t('form.passwordPlaceholder')"
                data-testid="registration-password-input"
                @blur="$v.password.$touch()"
            />
            <span
                v-if="$v.password.$error"
                class="alert alert-danger"
            >
            {{ $v.password.$errors[0].$message }}
          </span>
          </div>
        </div>

        <div class="row form__row">
          <div class="col-md-6">
            <label for="street">{{ $t("form.streetAddress") }} *</label>
            <input
                id="Street"
                v-model="state.billingAddress.street"
                name="Street"
                type="text"
                autocomplete="Street"
                class="form-control"
                :class="[
              $v.billingAddress.street.$error
                ? 'border-danger'
                : 'border-normal',
            ]"
                :placeholder="$t('form.streetPlaceholder')"
                data-testid="registration-street-input"
                @blur="$v.billingAddress.street.$touch()"
            />
            <span
                v-if="$v.billingAddress.street.$error"
                class="alert alert-danger"
            >
            {{ $v.billingAddress.street.$errors[0].$message }}
          </span>
          </div>

          <div class="col-md-6">
            <label for="zipcode">{{ $t("form.postalCode") }} *</label>
            <input
                id="zipcode"
                v-model="state.billingAddress.zipcode"
                name="zipcode"
                type="text"
                autocomplete="zipcode"
                class="form-control"
                :class="[
              $v.billingAddress.zipcode.$error
                ? 'border-danger'
                : 'border-normal',
            ]"
                :placeholder="$t('form.postalCodePlaceholder')"
                data-testid="registration-zipcode-input"
                @blur="$v.billingAddress.zipcode.$touch()"
            />
            <span
                v-if="$v.billingAddress.zipcode.$error"
                class="alert alert-danger"
            >
            {{ $v.billingAddress.zipcode.$errors[0].$message }}
          </span>
          </div>
        </div>

        <div class="row form__row">
          <div class="col-md-6">
            <label for="city">{{ $t("form.city") }} *</label>
            <input
                id="city"
                v-model="state.billingAddress.city"
                name="city"
                type="text"
                autocomplete="city"
                class="form-control"
                :class="[
              $v.billingAddress.city.$error
                ? 'border-danger'
                : 'border-normal',
            ]"
                :placeholder="$t('form.cityPlaceholder')"
                data-testid="registration-city-input"
                @blur="$v.billingAddress.city.$touch()"
            />
            <span
                v-if="$v.billingAddress.city.$error"
                class="alert alert-danger"
            >
            {{ $v.billingAddress.city.$errors[0].$message }}
          </span>
          </div>
          <div class="col-md-6">
            <SharedCountryStateInput
                v-model:countryId="state.billingAddress.countryId"
                v-model:stateId="state.billingAddress.countryStateId"
                :country-id-validation="$v.billingAddress.countryId"
                :state-id-validation="$v.billingAddress.countryStateId"
                class="col-span-12 md:col-span-4"
            />
          </div>
        </div>

        <div class="row form__row">
          <div class="col-md-12">
            <div class="form-check checkbox">
              <input
                  class="form-check-input form-control"
                  type="checkbox" value=""
                  id="check32" />
              <label class="form-check-label" for="check32" v-html="$t('checkout.termsAdnConditionsLabel')"></label>
            </div>
          </div>
        </div>

      </div><!-- /.form__body -->

      <div class="form__foot">
        <button type="submit" class="btn btn-default btn-danger form__btn">{{$t('account.create')}}</button>
      </div><!-- /.form__foot -->
    </form>
  </div>
</template>
