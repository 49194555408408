import { default as _91_46_46_46all_930i5ctWJQq5Meta } from "/vercel/path0/pages/[...all].vue?macro=true";
import { default as addressED39XkEx1LMeta } from "/vercel/path0/pages/account/address.vue?macro=true";
import { default as indexqOnLrLMUjLMeta } from "/vercel/path0/pages/account/index.vue?macro=true";
import { default as orderTLe4nSwR9NMeta } from "/vercel/path0/pages/account/order.vue?macro=true";
import { default as paymentV3pkwItuAdMeta } from "/vercel/path0/pages/account/payment.vue?macro=true";
import { default as profileLQWMAtlTUmMeta } from "/vercel/path0/pages/account/profile.vue?macro=true";
import { default as recoverhtXUjKKSqpMeta } from "/vercel/path0/pages/account/recover.vue?macro=true";
import { default as accountFI8qYzMM8ZMeta } from "/vercel/path0/pages/checkout/account.vue?macro=true";
import { default as cartfC7LJBHqlCMeta } from "/vercel/path0/pages/checkout/cart.vue?macro=true";
import { default as indexraiq4bUafSMeta } from "/vercel/path0/pages/checkout/index.vue?macro=true";
import { default as indexDdll4vfR4JMeta } from "/vercel/path0/pages/checkout/success/[id]/index.vue?macro=true";
import { default as paidNswHEDxk6GMeta } from "/vercel/path0/pages/checkout/success/[id]/paid.vue?macro=true";
import { default as unpaid61KGWjv8ShMeta } from "/vercel/path0/pages/checkout/success/[id]/unpaid.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as newsletter_45subscribep2E1MYmvn4Meta } from "/vercel/path0/pages/newsletter-subscribe.vue?macro=true";
import { default as personalize_45step_451q1UNBGdKkEMeta } from "/vercel/path0/pages/personalize-step-1.vue?macro=true";
import { default as personalize_45step_452Zb3RMweio4Meta } from "/vercel/path0/pages/personalize-step-2.vue?macro=true";
import { default as reset_45password7wK2GPZgVKMeta } from "/vercel/path0/pages/reset-password.vue?macro=true";
import { default as searchBIFC2dE21WMeta } from "/vercel/path0/pages/search.vue?macro=true";
import { default as shopwareRPK9liJ28YMeta } from "/vercel/path0/pages/shopware.vue?macro=true";
import { default as wishlistakmJn3oDDeMeta } from "/vercel/path0/pages/wishlist.vue?macro=true";
export default [
  {
    name: "all___de-DE",
    path: "/:all(.*)*",
    component: () => import("/vercel/path0/pages/[...all].vue")
  },
  {
    name: "all___fr-CH",
    path: "/fr-CH/:all(.*)*",
    component: () => import("/vercel/path0/pages/[...all].vue")
  },
  {
    name: "account-address___de-DE",
    path: "/account/address",
    meta: addressED39XkEx1LMeta || {},
    component: () => import("/vercel/path0/pages/account/address.vue")
  },
  {
    name: "account-address___fr-CH",
    path: "/fr-CH/account/address",
    meta: addressED39XkEx1LMeta || {},
    component: () => import("/vercel/path0/pages/account/address.vue")
  },
  {
    name: "account___de-DE",
    path: "/account",
    meta: indexqOnLrLMUjLMeta || {},
    component: () => import("/vercel/path0/pages/account/index.vue")
  },
  {
    name: "account___fr-CH",
    path: "/fr-CH/account",
    meta: indexqOnLrLMUjLMeta || {},
    component: () => import("/vercel/path0/pages/account/index.vue")
  },
  {
    name: "account-order___de-DE",
    path: "/account/order",
    meta: orderTLe4nSwR9NMeta || {},
    component: () => import("/vercel/path0/pages/account/order.vue")
  },
  {
    name: "account-order___fr-CH",
    path: "/fr-CH/account/order",
    meta: orderTLe4nSwR9NMeta || {},
    component: () => import("/vercel/path0/pages/account/order.vue")
  },
  {
    name: "account-payment___de-DE",
    path: "/account/payment",
    meta: paymentV3pkwItuAdMeta || {},
    component: () => import("/vercel/path0/pages/account/payment.vue")
  },
  {
    name: "account-payment___fr-CH",
    path: "/fr-CH/account/payment",
    meta: paymentV3pkwItuAdMeta || {},
    component: () => import("/vercel/path0/pages/account/payment.vue")
  },
  {
    name: "account-profile___de-DE",
    path: "/account/profile",
    meta: profileLQWMAtlTUmMeta || {},
    component: () => import("/vercel/path0/pages/account/profile.vue")
  },
  {
    name: "account-profile___fr-CH",
    path: "/fr-CH/account/profile",
    meta: profileLQWMAtlTUmMeta || {},
    component: () => import("/vercel/path0/pages/account/profile.vue")
  },
  {
    name: "account-recover___de-DE",
    path: "/account/recover",
    component: () => import("/vercel/path0/pages/account/recover.vue")
  },
  {
    name: "account-recover___fr-CH",
    path: "/fr-CH/account/recover",
    component: () => import("/vercel/path0/pages/account/recover.vue")
  },
  {
    name: "checkout-account___de-DE",
    path: "/checkout/account",
    meta: accountFI8qYzMM8ZMeta || {},
    component: () => import("/vercel/path0/pages/checkout/account.vue")
  },
  {
    name: "checkout-account___fr-CH",
    path: "/fr-CH/checkout/account",
    meta: accountFI8qYzMM8ZMeta || {},
    component: () => import("/vercel/path0/pages/checkout/account.vue")
  },
  {
    name: "checkout-cart___de-DE",
    path: "/checkout/cart",
    meta: cartfC7LJBHqlCMeta || {},
    component: () => import("/vercel/path0/pages/checkout/cart.vue")
  },
  {
    name: "checkout-cart___fr-CH",
    path: "/fr-CH/checkout/cart",
    meta: cartfC7LJBHqlCMeta || {},
    component: () => import("/vercel/path0/pages/checkout/cart.vue")
  },
  {
    name: "checkout___de-DE",
    path: "/checkout",
    meta: indexraiq4bUafSMeta || {},
    component: () => import("/vercel/path0/pages/checkout/index.vue")
  },
  {
    name: "checkout___fr-CH",
    path: "/fr-CH/checkout",
    meta: indexraiq4bUafSMeta || {},
    component: () => import("/vercel/path0/pages/checkout/index.vue")
  },
  {
    name: "checkout-success-id___de-DE",
    path: "/checkout/success/:id()",
    component: () => import("/vercel/path0/pages/checkout/success/[id]/index.vue")
  },
  {
    name: "checkout-success-id___fr-CH",
    path: "/fr-CH/checkout/success/:id()",
    component: () => import("/vercel/path0/pages/checkout/success/[id]/index.vue")
  },
  {
    name: "checkout-success-id-paid___de-DE",
    path: "/checkout/success/:id()/paid",
    component: () => import("/vercel/path0/pages/checkout/success/[id]/paid.vue")
  },
  {
    name: "checkout-success-id-paid___fr-CH",
    path: "/fr-CH/checkout/success/:id()/paid",
    component: () => import("/vercel/path0/pages/checkout/success/[id]/paid.vue")
  },
  {
    name: "checkout-success-id-unpaid___de-DE",
    path: "/checkout/success/:id()/unpaid",
    component: () => import("/vercel/path0/pages/checkout/success/[id]/unpaid.vue")
  },
  {
    name: "checkout-success-id-unpaid___fr-CH",
    path: "/fr-CH/checkout/success/:id()/unpaid",
    component: () => import("/vercel/path0/pages/checkout/success/[id]/unpaid.vue")
  },
  {
    name: "login___de-DE",
    path: "/login",
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "login___fr-CH",
    path: "/fr-CH/login",
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "newsletter-subscribe___de-DE",
    path: "/newsletter-subscribe",
    component: () => import("/vercel/path0/pages/newsletter-subscribe.vue")
  },
  {
    name: "newsletter-subscribe___fr-CH",
    path: "/fr-CH/newsletter-subscribe",
    component: () => import("/vercel/path0/pages/newsletter-subscribe.vue")
  },
  {
    name: "personalize-step-1___de-DE",
    path: "/personalize-step-1",
    meta: personalize_45step_451q1UNBGdKkEMeta || {},
    component: () => import("/vercel/path0/pages/personalize-step-1.vue")
  },
  {
    name: "personalize-step-1___fr-CH",
    path: "/fr-CH/personalize-step-1",
    meta: personalize_45step_451q1UNBGdKkEMeta || {},
    component: () => import("/vercel/path0/pages/personalize-step-1.vue")
  },
  {
    name: "personalize-step-2___de-DE",
    path: "/personalize-step-2",
    meta: personalize_45step_452Zb3RMweio4Meta || {},
    component: () => import("/vercel/path0/pages/personalize-step-2.vue")
  },
  {
    name: "personalize-step-2___fr-CH",
    path: "/fr-CH/personalize-step-2",
    meta: personalize_45step_452Zb3RMweio4Meta || {},
    component: () => import("/vercel/path0/pages/personalize-step-2.vue")
  },
  {
    name: "reset-password___de-DE",
    path: "/reset-password",
    component: () => import("/vercel/path0/pages/reset-password.vue")
  },
  {
    name: "reset-password___fr-CH",
    path: "/fr-CH/reset-password",
    component: () => import("/vercel/path0/pages/reset-password.vue")
  },
  {
    name: "search___de-DE",
    path: "/search",
    component: () => import("/vercel/path0/pages/search.vue")
  },
  {
    name: "search___fr-CH",
    path: "/fr-CH/search",
    component: () => import("/vercel/path0/pages/search.vue")
  },
  {
    name: "shopware___de-DE",
    path: "/shopware",
    component: () => import("/vercel/path0/pages/shopware.vue")
  },
  {
    name: "shopware___fr-CH",
    path: "/fr-CH/shopware",
    component: () => import("/vercel/path0/pages/shopware.vue")
  },
  {
    name: "wishlist___de-DE",
    path: "/wishlist",
    component: () => import("/vercel/path0/pages/wishlist.vue")
  },
  {
    name: "wishlist___fr-CH",
    path: "/fr-CH/wishlist",
    component: () => import("/vercel/path0/pages/wishlist.vue")
  }
]