<script setup lang="ts">

</script>

<template>
  <ul class="steps--alt section__steps--alt">
    <li class="step--alt">
      <div class="step__inner">
        <div class="step__count">
          <img src="assets/images/svg/1.svg" alt="" width="20" height="56">
        </div><!-- /.step__icon -->

        <div class="step__icon">
          <i class="ico-balloon">
            <img src="assets/images/svg/balloons.svg" alt="" width="76" height="70">
          </i>
        </div><!-- /.step__count -->
      </div><!-- /.step__inner -->

      <div class="step__content">
        <p v-html="$t('product.step_1')"></p>
      </div><!-- /.step__inner -->
    </li><!-- /.step -->

    <li class="step--alt">
      <div class="step__inner">
        <div class="step__count">
          <img src="assets/images/svg/2.svg" alt="" width="30" height="56">
        </div><!-- /.step__count -->

        <div class="step__icon">
          <i class="ico-balloon">
            <img src="assets/images/svg/candy.svg" alt="" width="65" height="56">
          </i>
        </div><!-- /.step__icon -->
      </div><!-- /.step__inner -->

      <div class="step__content">
        <p v-html="$t('product.step_2')"></p>
      </div><!-- /.step__inner -->
    </li><!-- /.step -->

    <li class="step--alt">
      <div class="step__inner">
        <div class="step__count">
          <img src="assets/images/svg/3.svg" alt="" width="30" height="56">
        </div><!-- /.step__icon -->

        <div class="step__icon">
          <i class="ico-balloon">
            <img src="assets/images/svg/gift-cart.svg" alt="" width="66" height="49">
          </i>
        </div><!-- /.step__count -->
      </div><!-- /.step__inner -->

      <div class="step__content">
        <p v-html="$t('product.step_3')"></p>
      </div><!-- /.step__inner -->
    </li><!-- /.step -->

    <li class="step--alt">
      <div class="step__inner">
        <div class="step__count">
          <img src="assets/images/svg/4.svg" alt="" width="32" height="56">
        </div><!-- /.step__count -->

        <div class="step__icon">
          <i class="ico-balloon">
            <img src="assets/images/svg/notes.svg" alt="" width="58" height="61">
          </i>
        </div><!-- /.step__icon -->
      </div><!-- /.step__inner -->

      <div class="step__content">
        <p v-html="$t('product.step_4')"></p>
      </div><!-- /.step__inner -->
    </li><!-- /.step -->

    <li class="step--alt">
      <div class="step__inner">
        <div class="step__count">
          <img src="assets/images/svg/5.svg" alt="" width="29" height="56">
        </div><!-- /.step__count -->

        <div class="step__icon">
          <i class="ico-balloon">
            <img src="assets/images/svg/calendar-alt.svg" alt="" width="75" height="66">
          </i>
        </div><!-- /.step__icon -->
      </div><!-- /.step__inner -->

      <div class="step__content">
        <p v-html="$t('product.step_5')"></p>
      </div><!-- /.step__inner -->
    </li><!-- /.step -->
  </ul>
</template>