<script setup lang="ts">
import PersonalizeCheckoutCartItem from "~/components/checkout/PersonalizeCheckoutCartItem.vue";
import ProductsSlider from "~/components/cms/ProductsSlider.vue";
import {getProductStream} from "~/components/utilities";
import {getPrefix} from "~/i18n/src/helpers/prefix";

const props = defineProps<{
  controller: ReturnType<typeof useModal>,
  successMessage?: any,
  class: any
}>();

const emits = defineEmits<{
  (e: "success"): void;
  (e: "close"): void;
}>();

const { cartItems, totalPrice, isEmpty } = useCart();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const router = useRouter();
const { availableLocales, defaultLocale } =
    useI18n();
const prefix = getPrefix(
    availableLocales,
    router.currentRoute.value.name as string,
    defaultLocale,
);
const {
  getLanguageIdFromCode,
} = useInternationalization();
let languageToChangeId: string | null = null;

languageToChangeId = getLanguageIdFromCode(
    prefix ? prefix : defaultLocale,
);

const breakpoints = {
  // 200px and up
  200: {
    itemsToShow: 1,
    snapAlign: 'center',
  },
  // 500px and up
  500: {
    itemsToShow: 2,
    snapAlign: 'center',
  },
  // 700px and up
  700: {
    itemsToShow: 2,
    snapAlign: 'center',
  },
  // 1024 and up
  1024: {
    itemsToShow: 3,
    snapAlign: 'start',
  }
};

const stream = await getProductStream('0190dac04e8b7a4485a312f393f77f1e', languageToChangeId);

</script>

<template>

    <aside class="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
      <h2 id="slide-over-title" class="text-center">
        <img src="/assets/images/svg/basket.svg" alt="" width="40" height="40" style="margin-right:10px;">
        {{ $t('checkout.miniCartHeading') }}
      </h2>

      <div class="alert alert-success mt-4" v-if="successMessage" v-html="successMessage"></div>

      <div class="mt-4">
        <div class="flow-root">
          <ul v-if="!isEmpty"
              role="list" class="list-unstyled">
            <li>
                <template v-for="cartItem in cartItems" :key="cartItem.id" class="flex py-6">
                  <PersonalizeCheckoutCartItem :cart-item="cartItem" />
                </template>
            </li>
          </ul>
          <div v-else class="fs-4 text-center">
            {{ $t('checkout.emptyCartMessage') }}
          </div>
        </div>
      </div>
    </aside>
    <aside aria-label="Side Cart Links" class="mt-2 pt-2">
      <div class="row">
        <div class="col-md-6 col-12 text-center mb-3">
          <button
              type="button"
              class="btn btn-default btn-primary btn-gray-prime btn-icon btn-icon--right btn-sm btn-mobile-w-full"
              data-testid="cart-continue-button"
              @click="(emits('close'))">{{ $t('checkout.continueShopping') }}
          </button>
        </div>
        <div class="col-md-6 col-12 text-center">
          <NuxtLink class="btn btn-default btn-danger btn-sm btn-mobile-w-full" @click="(emits('close'))" :to="formatLink(isEmpty ? '' : '/personalize-step-1')">
            {{ $t('checkout.linkCheckout') }}
          </NuxtLink>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12">
          <h3 class="text-center mb-4" style="color:#BF6587">{{ $t('checkout.cart.also.interesting') }}</h3>

          <products-slider :slides="stream" :breakpoints="breakpoints"></products-slider>
        </div>
      </div>

    </aside>
</template>
