<script setup lang="ts">
import { ApiClientError } from "@shopware/api-client";
import type { ApiError } from "@shopware/api-client";
import {klaviyoIdentify} from "~/components/utilities/GtmTrackings";

const emits = defineEmits<{
  (e: "success"): void;
  (e: "close"): void;
}>();
const router = useRouter();
const { isLoggedIn, login, user } = useUser();
const { t } = useI18n();
const { refreshSessionContext } = useSessionContext();
const { mergeWishlistProducts } = useWishlist();
const { pushSuccess } = useNotifications();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const loginErrors = ref<string[]>([]);
const activeTab = ref(0)

const formData = ref({
  username: "",
  password: "",
  remember: true,
});

const goToRegister = () => {
  emits("close");
  router.push(formatLink("/register"));
};

const goToRecoverPassword = () => {
  emits("close");
}

const { resolveApiErrors } = useApiErrorsResolver("account_login");

const invokeLogin = async (): Promise<void> => {
  loginErrors.value = [];
  try {
    // TODO: remove this line once the https://github.com/shopware/frontends/issues/112 issue is fixed
    await refreshSessionContext();
    await login(formData.value);
    emits("success");
    pushSuccess(t("account.messages.loggedInSuccess"));
    emits("close");
    mergeWishlistProducts();
    klaviyoIdentify(user);
  } catch (error) {
    if (error instanceof ApiClientError) {
      loginErrors.value = resolveApiErrors(error.details.errors as ApiError[]);
    }
  }
};

const emailImputElement = ref();
useFocus(emailImputElement, { initialValue: true });

const activateTab = (index: any)=> {
  activeTab.value = index;
}

</script>
<template>
  <div
    id="modal-headline"
    role="form"
    title="Login form"
    aria-label="Login form"
    class="">
    <div v-if="!isLoggedIn" class="max-w-md w-full space-y-8">
      <div class="profile-main mb-5">
        <i class="ico-profile-alt">
          <img src="assets/images/svg/profile-alt.svg" alt="" width="67" height="66">
        </i>

        <span>{{$t('account.sign.in')}}</span>
      </div>

      <div class="tabs">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link" @click="activateTab(0)" :class="{ 'active': activeTab === 0 }" id="login-tab" data-bs-toggle="tab" data-bs-target="#login" type="button" role="tab" aria-controls="login" aria-selected="true">{{$t('account.header.i.have')}}</button>
          </li>

          <li class="nav-item" role="presentation">
            <button class="nav-link" @click="activateTab(1)" :class="{ 'active': activeTab === 1 }" id="register-tab" data-bs-toggle="tab" data-bs-target="#register" type="button" role="tab" aria-controls="register" aria-selected="false">{{$t('account.header.im.new')}}</button>
          </li>
        </ul>

        <div class="tab-content tab-content--alt" id="myTabContent">
          <div class="tab-pane fade" :class="{ 'show active': activeTab === 0 }" id="login" role="tabpanel" aria-labelledby="login-tab">
            <div class="form form--login">
              <form class="mt-8 space-y-6" @submit.prevent="invokeLogin">
                <input v-model="formData.remember"
                    type="hidden"
                    name="remember"
                    data-testid="login-remember-input"
                />
                <div class="form__head">
                  <p>{{ $t("account.signInLabel") }}</p>
                </div>
                <div class="form__body">
                  <div class="form__row">
                    <label for="email" class="form-label">{{ $t("form.email") }}</label>

                    <input
                        id="email-address"
                        ref="emailImputElement"
                        v-model="formData.username"
                        name="email"
                        type="email"
                        autocomplete="email"
                        required
                        class="form-control"
                        :placeholder="$t('form.email')"
                        data-testid="login-email-input"
                    />
                  </div><!-- /.form__row -->

                  <div class="form__row">
                    <label for="inputPassword" class="form-label">{{ $t("form.password") }}</label>

                    <input
                        id="password"
                        v-model="formData.password"
                        name="password"
                        type="password"
                        autocomplete="current-password"
                        required
                        class="form-control"
                        :placeholder="$t('form.password')"
                        data-testid="login-password-input"
                    />
                  </div><!-- /.form__row -->
                </div>

                <slot :data="formData" />

                <slot name="error">
                  <div v-if="loginErrors.length"
                      class=""
                      data-testid="login-errors-container">
                    <div class="text-center">
                      <div class="alert alert-danger" v-for="error in loginErrors">
                        {{ $t('login.error') }}
                      </div>
                    </div>
                  </div>
                </slot>

                <div class="form__foot">
                  <p>
                    <NuxtLink @click="goToRecoverPassword" to="/account/recover" class="link-forgot">{{$t('account.password.forgotten')}}</NuxtLink>
                  </p>

                  <button class="btn btn-default btn-danger form__btn" type="submit"
                          data-testid="login-submit-button">
                    {{ $t("account.signIn") }}
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div class="tab-pane fade" :class="{ 'show active': activeTab === 1 }" id="register" role="tabpanel" aria-labelledby="register-tab">
            <div class="form form--register">
              <AccountRegisterForm />
            </div><!-- /.form form-login -->
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h2>{{ $t("account.loggedInInfo") }}</h2>
    </div>
  </div>
</template>
