<script setup lang="ts">
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import SwProductCard from "~/components/cms/SwProductCard.vue";

const props = defineProps({
  breakpoints: {
     default: {
      // 200px and up
      200: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      // 500px and up
      500: {
        itemsToShow: 2,
        snapAlign: 'center',
      },
      // 700px and up
      700: {
        itemsToShow: 3,
        snapAlign: 'center',
      },
      // 1024 and up
      1024: {
        itemsToShow: 4,
        snapAlign: 'start',
      }
    }
  },
  slides: {},
});

const settings = {
  itemsToShow: 1,
  snapAlign: 'center',
}

let breakpoints = props.breakpoints;

const getDisplayName = (item: any) => {
  return item?.product?.name || item?.name;
}

const getRegularPrice = (item: any) => {
  if (item && item.product) {
    return item.product?.calculatedPrice?.listPrice?.price ?? item.product?.calculatedPrice?.totalPrice;
  } else {
    return item?.calculatedPrice?.listPrice?.price ?? item?.calculatedPrice?.totalPrice;
  }
}

const getDiscountPrice = (item: any) => {
  if (item && item.product) {
    return item.product?.calculatedPrice?.totalPrice
  } else {
    return item?.calculatedPrice?.totalPrice
  }
}

const isDiscountActive = (item: any) => {
  if (item && item.product) {
    return item.product?.calculatedPrice?.listPrice?.price;
  } else {
    return item?.calculatedPrice?.listPrice?.price;
  }
}

</script>

<template>
  <Carousel v-bind="settings" :breakpoints="breakpoints">
    <Slide v-for="(item, index) in slides" :key="index">
        <SwProductCard
            class="carousel__item"
            :product="item"
        />
    </Slide>

    <template #addons>
      <Navigation />
    </template>
  </Carousel>
</template>