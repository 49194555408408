<script setup lang="ts">
const props = defineProps<{
  controller: ReturnType<typeof useModal>;
  popupClass?: any;
  size?: any;
}>();

const { controller } = toRefs(props);
const { isOpen, close } = controller.value;

const { escape } = useMagicKeys();

watch(escape, () => {
  isOpen.value && close();
});

const modalContentElement = ref();
onClickOutside(modalContentElement, () => close());
</script>

<template>
  <Teleport to="body">
    <Transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-200 transform"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-show="isOpen" class="modal  fade" :class="[{ 'show': isOpen }, popupClass]">
      <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered" :class="[size]">
        <div class="modal-header">
          <button type="button" @click="() => close()" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <i class="ico-close">
              <img src="assets/images/svg/close.svg" alt="" width="14" height="14">
            </i>
          </button>
        </div>
          <div v-if="isOpen"
              id="modal-content"
              ref="modalContentElement"
              class="modal-content"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline">
            <div class="modal-body">
              <div class="container-fluid">
              <slot></slot>
              </div>
            </div>
            </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>
