const currency = "CHF"

function getItems(productData) {
    // Initialize an empty array to store the items
    const items = [];
    // Check if productData exists and is an array
    if (Array.isArray(productData)) {
        productData.forEach((product) => {
            const categoryIds = product.categoryIds || [];
            const categoryCount = categoryIds.length;

            // Create an object to store category information
            const categoryInfo = {};

            // Set item_category to the first category if it exists
            if (categoryCount > 0) {
                categoryInfo.item_category = categoryIds[0];
            }

            // Set additional categories as item_category1, item_category2, etc.
            for (let i = 1; i < categoryCount; i++) {
                categoryInfo[`item_category${i}`] = categoryIds[i];
            }

            items.push({
                item_name: product.translated.name,
                item_brand: product.manufacturer?.translated.name,
                item_id: product.productNumber,
                quantity: 1, // You can set the quantity to a default value
                price: product.calculatedPrice.totalPrice,
                ...categoryInfo, // Spread the categoryInfo object into the item
            });
        });
    }

    return items;
}

function getItem(productData) {
    const item = [];

    const categoryIds = productData.categoryIds || [];
    const categoryCount = categoryIds.length;

    // Create an object to store category information
    const categoryInfo = {};

    // Set item_category to the first category if it exists
    if (categoryCount > 0) {
        categoryInfo.item_category = categoryIds[0];
    }

    // Set additional categories as item_category1, item_category2, etc.
    for (let i = 1; i < categoryCount; i++) {
        categoryInfo[`item_category${i}`] = categoryIds[i];
    }

    item.push({
        item_name: productData.translated.name,
        item_brand: productData.manufacturer.translated.name,
        item_id: productData.productNumber,
        quantity: 1, // You can set the quantity to a default value
        price: productData.calculatedPrice.totalPrice,
        ...categoryInfo, // Spread the categoryInfo object into the item
    });
    return item
}

function trustedShopsSuccessTracking(orderData) {
    const orderDataFilter = orderData.value
    const orderItems = getLineItems(orderDataFilter?.lineItems)

    if(Object.keys(orderData.value).length > 0) {
        return {
            currency: currency,
            transaction_id: orderDataFilter.orderNumber,
            value: orderDataFilter?.price.totalPrice,
            tax: orderDataFilter?.price.calculatedTaxes[0].tax,
            shipping: orderDataFilter?.shippingTotal,
            coupon: orderDataFilter?.campaignCode,
            items: orderItems,
        }
    }

    return null;
}

function getLineItems(orderItems) {
    const items = [];

    if (Array.isArray(orderItems)) {
        orderItems.forEach((item) => {

            items.push({
                item_name: item.label,
                item_id: item.payload.productNumber,
                quantity: item.quantity,
                price: item.totalPrice,
            });
        })
    }

    return items
}

export {
    getItems,
    getItem,
    trustedShopsSuccessTracking,
}