import Cookies from "js-cookie";
import axios from "axios";

export function removeWhiteBackgroundFromImage(imageUrl, callback) {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // Enable CORS for external images
    img.onload = function() {
        const canvas = document.getElementById('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        removeWhiteBackground(imageData);
        ctx.putImageData(imageData, 0, 0);
        const transparentImageUrl = canvas.toDataURL(); // Get Data URL of the modified image
        callback(transparentImageUrl);
    };
    img.src = imageUrl;
}

export function removeWhiteBackground(imageData) {
    for (let i = 0; i < imageData.data.length; i += 4) {
        const red = imageData.data[i];
        const green = imageData.data[i + 1];
        const blue = imageData.data[i + 2];
        const alpha = imageData.data[i + 3];
        if (red > 240 && green > 240 && blue > 240) {
            imageData.data[i + 3] = 0; // Set alpha to 0 for white pixels
        }
    }
}

export function findObjectByType(obj, targetType) {
    // Base case: if the current object's type matches the target type, return it
    if (obj.type === targetType) {
       return obj;
    }

    // Traverse through sections
    if (obj.sections) {
        for (const section of obj.sections) {
            // Traverse through blocks in each section
            if (section.blocks) {
                for (const block of section.blocks) {
                    if (block.type === targetType) {
                        return block?.slots[0]?.data.listing;
                    }
                }
            }
        }
    }

    // If no matching object is found, return null
    return null;
}

export function checkProductInCart(cartItems, product) {
    let isProductInCart = false; // Initialize a flag to track if the product is in the cart
    cartItems.value.forEach(cartItem => {
        if (cartItem.referencedId === product.id) {
            isProductInCart = true; // If a matching product is found in the cart, set the flag to true
        }
    });
    return isProductInCart;
}

export function getCartItem(cartItems, product) {
    return getCartItemById(cartItems, product.id);
}
export function getCartItemById(cartItems, product) {
    for (const cartItem of cartItems.value) {
        if (cartItem.referencedId === product) {
            return cartItem; // If a matching product is found in the cart, return that cart item
        }
    }
    return null; // If no matching product is found in the cart, return null
}

export async function removeCartItemsByIds(body) {
    const runtimeConfig = useRuntimeConfig();
    if (body) {
        const url = '/store-api/checkout/cart/line-item/delete';
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Sw-Access-Key': 'SWSCMHL2RHFKQXA3SFPQSW1UBW',
                'Sw-Context-Token': Cookies.get('sw-context-token')
            },
            body: body
        };
        try {
            const response = await fetch(url, options);
            const data = await response.json();
        } catch (error) {
            console.error(error);
        }
    }
}
export async function getDisabledDates() {
    const runtimeConfig = useRuntimeConfig();
    const url = runtimeConfig.public.shopware.endpoint+'lfh/deliverydate/disabled_dates';
    const options = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Sw-Access-Key': 'SWSCMHL2RHFKQXA3SFPQSW1UBW',
            'Sw-Context-Token': Cookies.get('sw-context-token')
        },
    };
    try {
        const response = await fetch(url, options);
        return await response.json();
    } catch (error) {
        console.error(error);
    }
}

export function readLocalStorage() {
    if (process.client) {
        // Read values from local storage
        const prefix = localStorage.getItem('prefix');
        const firstName = localStorage.getItem('firstName');
        const lastName = localStorage.getItem('lastName');
        const street = localStorage.getItem('street');
        const postcode = localStorage.getItem('postcode');
        const city = localStorage.getItem('city');
        const deliveryDate = localStorage.getItem('deliveryDate');

        // Parse the date string
        const date = new Date(deliveryDate);

        // Get day, month, and year
        const day = date.getDate().toString().padStart(2, '0'); // Add leading zero if needed
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 since months are zero-indexed
        const year = date.getFullYear();

        // Format the date
        const formattedDate = `${day}.${month}.${year}`;

        // Create an object with the retrieved values
        return {
            prefix: prefix,
            firstName: firstName,
            lastName: lastName,
            street: street,
            postcode: postcode,
            city: city,
            deliveryDate: formattedDate
        };
    }
    return {};
}

export function getSortedOrders(orders) {
    if (orders && orders.length > 0) {
        orders.sort((a, b) => b.orderNumber - a.orderNumber);
    }
    return orders
}

export function removeProductById(productId, products) {

    for (let i = 0; i < products.length; i++) {
        if (products[i].id === productId) {
            products.splice(i, 1);
            break; // Exit the loop after removing the product
        }
    }

    return products;
}

export async function recreateCart() {
    const storedPersonData = localStorage.getItem('unPaidCart');
    const cartItems = JSON.parse(storedPersonData);

    for (const item of cartItems) {
        const { addToCart, isInCart, quantity } = useAddToCart(item);
        quantity.value = item.quantity;
        if(!isInCart.value) {
            await addToCart();
        }
    }
}

export function getSanitizeUrl(rawUrl) {
    const sanitizedWords = [
        "124c71d524604ccbad6042edce3ac799",
        "#"
    ];

    let cleanUrl = rawUrl;

    // Loop through each word in the sanitizedWords array
    sanitizedWords.forEach(word => {
        // Use a regular expression to replace occurrences of each word
        let regex = new RegExp(escapeRegExp(word), 'g');
        cleanUrl = cleanUrl.replace(regex, '');
    });

    return cleanUrl;
}

export function rearrangeArray(array) {
    // Move '2nd image' (index 1) to the start
    array.splice(0, 0, array.splice(1, 1)[0]);

    if (array.length === 3) {
        // Move 'Third image' (index 2) to the second position
        array.splice(1, 0, array.splice(2, 1)[0]);
    }

    return array;
}

// Function to escape regular expression characters in sanitized words
function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}


export async function getProductBundles(product) {
    const runtimeConfig = useRuntimeConfig();

    const url = runtimeConfig.public.shopware.endpoint + `product/${product.id}/cross-selling`;

    const headers = {
        'Sw-access-key': runtimeConfig.public.shopware.accessToken,
        'Sw-Context-Token': Cookies.get('sw-context-token'),
        'sw-include-seo-urls': 1,
    };

    const requestBody = {}

    let crossSellings =  await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...headers,
        },
        body: JSON.stringify(requestBody),
    })
        .then((response) => response.json())
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.error('Error:', error);
        });

    const Bundels = crossSellings.filter(item => item?.crossSelling?.translated?.name === 'Bundle');
    if(Bundels.length) {
        return Bundels[0];
    }
    return null;
}

export async function getProductStream(streamId, languageId) {

    const shopwareEndpoint = "https://dev.luftkuss.ch/store-api/";
    const shopwareAccessToken = "SWSCMHL2RHFKQXA3SFPQSW1UBW";
    const { $adminGetAccessData, $adminApiEndpoint } = useNuxtApp()
    const tokenData = await $adminGetAccessData();
    const bearerToken = tokenData.data.access_token;


    try {
        const apiUrl = $adminApiEndpoint + "/product-stream/"+streamId;
        const apiUrlProducts = $adminApiEndpoint + "/search/product";
        const headers = {
            "Authorization": `Bearer ${bearerToken}`,
            "Content-Type": "application/json",
            "Sw-Language-Id": languageId
        };

        const response = await axios.get(apiUrl, {headers});

        if (!response.data || response.status !== 200) {
            throw new Error(`Request failed with status: ${response.status}`);
        }

        const requestBody = {
            filter: response.data.data.apiFilter,
            "associations": {
                "cover": []
            }
        }

        const url = shopwareEndpoint + `product`;

        const responseProducts = await axios.post(url, requestBody, {
            headers: {
                'Sw-Access-Key': shopwareAccessToken,
                'Sw-Context-Token': Cookies.get('sw-context-token'),
                'Content-Type': 'application/json',
                'sw-include-seo-urls': 'true' // Include any necessary headers
            }
        });

        if (responseProducts && responseProducts.data) {
            return responseProducts.data.elements;
        } else {
            throw new Error('Response or response data is undefined.');
        }

    } catch (error) {
        // Handle errors
        console.error("Error:", error);
        throw error; // Re-throw the error to propagate it further if needed
    }

}