import { createAdminAPIClient } from "@shopware/api-client";
import Cookies from "js-cookie";

declare module '#app' {
    interface NuxtApp {
        $adminApiEndpoint: string,
        $adminApiClient: any,
        $adminGetAccessData(): any
    }
}

export default defineNuxtPlugin((NuxtApp)=> {
    const runtimeConfig = useRuntimeConfig();
    let apiEndPoint: string;
    apiEndPoint = runtimeConfig.public?.shopware?.endpoint ?
        runtimeConfig.public.shopware.endpoint.replace("store-api/", "api") : 'no_endpoint';

    const adminApiClient = createAdminAPIClient({
        baseURL: apiEndPoint,
        sessionData: JSON.parse(Cookies.get("sw-admin-session-data") || "{}"),
        defaultHeaders: {
            'sw-include-seo-urls': '1',
        }
    });

    const getAccessData = async ()=> {
        return await login();
    }

    const login = () => {
        return adminApiClient.invoke("token post /oauth/token", {
            body: {
                grant_type: "client_credentials",
                client_id: runtimeConfig.app.api_client_id,
                client_secret: runtimeConfig.app.api_client_secret,
                //scopes: "write"
            }
        });
    };

    NuxtApp.provide("adminApiClient", adminApiClient);
    NuxtApp.provide("adminApiEndpoint", apiEndPoint);
    NuxtApp.provide("adminGetAccessData", getAccessData);
});