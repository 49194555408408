<script setup lang="ts">
const { isLoggedIn, logout, user } = useUser();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const loginModalController = useModal();
const isAccountMenuOpen = ref(false);

async function invokeLogout() {
  await logout();
  isAccountMenuOpen.value = false;
}
</script>
<template>

  <div class="md:w-auto min-w-140px flex justify-end">
    <div class="my-account-area">
        <div v-show="!isLoggedIn">
          <a href="javascript:;"
            data-testid="header-sign-in-link"
            @click="loginModalController.open"
          >
            <i class="ico-profile">
              <img src="assets/images/svg/profile.svg" alt="" width="24" height="24">
            </i>
            <span>{{ $t("account.menu.signIn") }}</span>
          </a>
        </div>
        <SharedModal :controller="loginModalController" popup-class="AccountLoginForm">
          <AccountLoginForm
            @close="loginModalController.close"
            @success="loginModalController.close"
          />
        </SharedModal>
        <div v-if="isLoggedIn">
          <NuxtLink :to="formatLink('/account')">
            <i class="ico-profile">
              <img src="assets/images/svg/profile.svg" alt="" width="24" height="24">
            </i>
            <!--span>{{ $t("account.menu.hello") }}, {{ user?.firstName }}!</span>-->
            <span>{{ $t("account.myaccount") }}</span>
          </NuxtLink>
          <div class="inset-y-2 flex items-center sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <div class="w-7 h-7 i-carbon-user text-secondary-600 hover:text-primary sm:hidden hover:text-primary" @click="isAccountMenuOpen = !isAccountMenuOpen"/>
            <button
              type="button"
              class="text-sm text-secondary-700 bg-transparent focus:outline-none hidden sm:block"
              data-testid="account-menu-hello-button"
              @click="isAccountMenuOpen = !isAccountMenuOpen"
            >
              {{ $t("account.menu.hello") }}, {{ user?.firstName }}!
            </button>

            <!-- Profile dropdown -->

          </div>
        </div>
    </div>
  </div>

</template>
