<script setup lang="ts">
import { getProductRoute } from "@shopware-pwa/helpers-next";

import { onClickOutside, useFocus, useMagicKeys } from "@vueuse/core";

withDefaults(
    defineProps<{
      displayTotal?: number;
    }>(),
    { displayTotal: 3 },
);

const emit = defineEmits<{
  (e: "link-clicked"): void;
}>();

const { searchTerm, search, getProducts, getTotal, loading } =
    useProductSearchSuggest();

// True when the search bar is active and user can type in the search field
const active = ref(false);

// Reference to the search container
const searchContainer = ref(null);
const searchInput = ref();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

watch(active, (value) => {
  const { focused } = useFocus(searchInput);

  focused.value = value;
});

// String the user has typed in the search field
const typingQuery = ref("");

watch(typingQuery, (value) => {
  if (value.length >= 3) {
    performSuggestSearch(value);
  }
});

// Defer the search request to prevent the search from being triggered too after typing
const performSuggestSearch = useDebounceFn((value) => {
  searchTerm.value = value;
  const filter = {
    "filter": [
      {
        "type": "not",
        "operator": "or",
        "queries": [
          {
            "type": "contains",
            "field": "categoryTree",
            "value": '018dd05d1f0e71b79f2183fbea710775'
          }
        ]
      }
    ]
  }
  search(filter);
}, 300);

// Suggest results will only be shown, when the search bar is active and the user has typed more than three characters in the search field
const showSuggest = computed(() => {
  return typingQuery.value.length >= 3 && active.value;
});

if (process.client) {
  onClickOutside(searchContainer, () => {
    active.value = false;
  });
}

const { enter } = useMagicKeys({ target: searchInput });
const { push } = useRouter();

const sideMenuModal = useSideMenuModal();

watch(enter, (value) => {
  if (!value) return;

  sideMenuModal.close();

  active.value = false;
  push("/search?search=" + typingQuery.value);
});

const searchAction = ()=> {
  sideMenuModal.close();
  active.value = false;
  emit('link-clicked')
  push("/search?search=" + typingQuery.value);
}

</script>

<template>
  <div class="search header__search" ref="searchContainer" :class="[active ? 'shadow-lg' : '']">
    <div class="search__inner">
      <label for="q" class="hidden">Search</label>

      <input
          id="h-search"
          name="h-search"
          ref="searchInput"
          v-model="typingQuery"
          data-testid="layout-search-input"
          type="text"
          aria-label="Search for products"
          class="search__field form-control"
          :placeholder="$t('form.searchPlaceholder')"
          @click="active = true"
      />

      <button @click="searchAction" type="button" class="search__btn btn btn-default btn-danger">
        <i class="ico-search">
          <img src="assets/images/svg/search.svg" alt="" width="14" height="14">
        </i>
      </button>
    </div>
    <div
        v-if="showSuggest"
        data-testid="layout-search-result-box"
        class="layout-search-result-box shadow-lg">
      <NuxtLink
          v-for="product in getProducts?.slice(0, displayTotal)"
          :key="product.id"
          :to="formatLink(getProductRoute(product))"
          data-testid="layout-search-suggest-link"
          @click="[(active = false), $emit('link-clicked')]"
      >
        <ProductSuggestSearch :product="product" />
      </NuxtLink>

      <div class="h-11 text-sm rounded-b-md p-3 text-center transition"
          style="clip-path: inset(0% 0% 0% 0%)">
        <div v-if="loading" class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div v-else>
          <NuxtLink
              v-if="getTotal > 0"
              data-testid="layout-search-result-box-more-link"
              :to="
            formatLink({ path: `/search`, query: { search: typingQuery } })
          "
              @click="[(active = false), $emit('link-clicked')]"
          >
            {{ $t("search.see") }}
            <span v-if="getTotal !== 1">{{ $t("search.all") }}</span>
            {{ getTotal }}
            <span>{{ $t("search.result") }} {{ getTotal }}</span>
          </NuxtLink>
          <div v-else data-testid="layout-search-result-box-no-result">
            {{ $t("search.noResults") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
